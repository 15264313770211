import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../features/auth/@core/services/auth.service';
import { URLS } from '../../shared/enums/urls.enum';

export const unauthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId))
    if (authService.isAuthenticated()) {
      router.navigateByUrl(URLS.DASHBOARD_PATH);
      return false;
    } else {
      return true;
    }

  return false;
};
