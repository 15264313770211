import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { appRoutes } from '../../../../shared/constants/routes.constants';
import { ListController } from '../../@core/api/list.api';
import { IList } from '../../@core/interfaces/list.interface';
@Component({
  selector: 'app-lists',
  standalone: true,
  imports: [TableModule, CardModule, RouterLink],
  templateUrl: './available-lists.component.html',
  styleUrl: './available-lists.component.scss',
})
export class ListsComponent implements OnInit {
  lists!: IList[];

  readonly route = appRoutes;

  constructor(private listService: ListController) {}

  ngOnInit(): void {
    this.listService.getLists().then((data) => {
      if (data) {
        this.lists = data;
      }
    });
  }
}
