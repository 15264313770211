import { Routes } from '@angular/router';
import { authGuard } from './@core/guards/auth.guard';
import { unauthGuard } from './@core/guards/unauth.guard';
import { applicationChildRoutes } from './layout/application-child-routes.routes';
import { appRoutes } from './shared/constants/routes.constants';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./layout/app-layout/app-layout.component').then((mod) => mod.AppLayoutComponent),
    children: applicationChildRoutes,
    canActivate: [authGuard],
  },
  {
    path: appRoutes.AUTH,
    loadChildren: () => import('./features/auth/auth.routes').then((m) => m.authRoutes),
    canActivate: [unauthGuard],
  },
  {
    redirectTo: '',
    path: '**',
    pathMatch: 'full',
  },
];
