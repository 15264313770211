import { Injectable } from '@angular/core';
import { ApiService } from '../../../../@core/api/api.service';
import { IListDetailDto } from '../interfaces/list-detail.interface';
import { IListDto } from '../interfaces/list.interface';

@Injectable({
  providedIn: 'root',
})
export class ListController {
  private readonly _path = '/lists';

  constructor(private apiService: ApiService) {}

  getLists(): Promise<IListDto[] | null> {
    return new Promise((resolve) => {
      this.apiService.get<IListDto[]>(this._path).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }

  getListById(id: string): Promise<IListDetailDto[] | null> {
    return new Promise((resolve) => {
      this.apiService.get<IListDetailDto[]>(`${this._path}/${id}`).subscribe({
        next: (response) => {
          resolve(response);
        },
        error: () => {
          resolve(null);
        },
      });
    });
  }
}
