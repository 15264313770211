import { Routes } from '@angular/router';
import { ListsComponent } from '../features/lists/components/available-lists/available-lists.component';
import { SearchDetailsComponent } from '../features/lists/components/search-details/search-details.component';
import { SearchesListComponent } from '../features/lists/components/searches-list/searches-list.component';
import { appRoutes } from '../shared/constants/routes.constants';

export const applicationChildRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: appRoutes.DASHBOARD,
  },
  {
    path: appRoutes.DASHBOARD,
    loadComponent: () => import('../features/dashboard/dashboard/dashboard.component').then((m) => m.DashboardComponent),
  },
  {
    path: appRoutes.LISTS,
    children: [
      {
        path: '',
        data: {
          breadcrumb: 'Lists',
        },
        component: ListsComponent,
      },
      {
        path: ':name/:id',
        data: {
          breadcrumb: 'List item',
        },
        component: SearchesListComponent,
      },
      {
        path: ':name/:id/:subId',
        data: {
          breadcrumb: 'Lead',
        },
        component: SearchDetailsComponent,
      },
    ],
  },
];
