import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../features/auth/@core/services/auth.service';
import { URLS } from '../../shared/enums/urls.enum';
import { INewAccessToken } from '../interfaces/new-access-token.interface';
import { AccountService } from '../services/account.service';
import { TokenService } from '../services/token.service';

export const tokenInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const accountService = inject(AccountService);
  const authService = inject(AuthService);
  const tokenService = inject(TokenService);
  const router = inject(Router);

  let isRefreshing = false;
  const refreshTokenSubject$ = new BehaviorSubject<string>(TokenService.getCurrentRefreshToken());

  const addTokenToRequest = (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
    return request.clone({
      setHeaders: {
        Authorization: token,
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });
  };

  const handleAuthError = (error: unknown): Observable<never> => {
    console.error('Authentication error:', error);
    authService.clearAuthData();
    router.navigate([URLS.LOGIN_PATH]);
    return throwError(() => new Error('Authentication failed'));
  };

  const handleRefreshToken = (): Observable<string> => {
    if (!isRefreshing) {
      isRefreshing = true;
      refreshTokenSubject$.next(null);

      const refreshToken = TokenService.getCurrentRefreshToken();
      if (!refreshToken) {
        return handleAuthError('No refresh token available');
      }

      return from(accountService.refreshAccessToken({ token: refreshToken })).pipe(
        switchMap((response: INewAccessToken) => {
          if (response && response.accessToken) {
            tokenService.setCurrentAccessToken(response.accessToken);
            refreshTokenSubject$.next(response.accessToken);
            return of(response.accessToken);
          } else {
            authService.clearAuthData();
            return throwError(() => new Error('No refresh token available'));
          }
        }),
        catchError((refreshError: HttpErrorResponse): Observable<never> => {
          refreshTokenSubject$.next(null);
          authService.clearAuthData();
          return throwError(() => refreshError);
        }),
        finalize(() => {
          isRefreshing = false;
        }),
      );
    }

    return refreshTokenSubject$.pipe(
      filter((token): token is string => token !== null),
      take(1),
    );
  };

  const accessToken = TokenService.getCurrentAccessToken();
  const initialRequest = accessToken ? addTokenToRequest(req, accessToken) : req;

  return next(initialRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && accessToken) {
        return handleRefreshToken().pipe(
          switchMap((newToken: string) => {
            return next(addTokenToRequest(req, newToken));
          }),
          catchError((refreshError: unknown) => {
            return handleAuthError(refreshError);
          }),
        );
      }
      return throwError(() => error);
    }),
  );
};
