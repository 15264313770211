<p-card
  header="{{ name }}"
  class="[&_.p-card]:rounded-xl [&_.p-card]:border [&_.p-card-content]:p-0 [&_.p-card]:border-solid [&_.p-card]:border-gray-200 [&_.p-card]:px-3 [&_.p-card]:py-2 [&_.p-card-title]:text-lg [&_.p-card-title]:font-bold [&_.p-card-title]:text-gray-700 [&_.p-card-title]:px-3.5 [&_.p-card-title]:pt-3 [&_.p-card-title]:mb-1 [&_.p-card-body]:p-0"
>
  <p-table [value]="listDetail" class="[&_.p-datatable-tbody>tr:last-child>td]:border-b-0">
    <ng-template pTemplate="header">
      <tr class="[&>th]:bg-transparent [&>th]:py-2.5 [&>th]:px-3.5 [&>th]:text-sm [&>th]:text-gray-700">
        <th>Name</th>
        <th>Job Title</th>
        <th>Location</th>
        <th>Summary</th>
        <th>Insight</th>
        <th>Linkedin</th>
        <th>Last harvest</th>
        <th>Error harvesting</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-listDetailItem>
      <tr class="[&>td]:py-2.5 [&>td]:px-3.5 [&>td]:text-sm">
        <td class="flex items-center gap-2">
          <p-avatar [image]="listDetailItem.picture" shape="circle" class="[&_.p-avatar]:size-8 [&_.p-avatar]:flex"></p-avatar>
          <a
            [routerLink]="[listDetailItem.name]"
            routerLinkActive="router-link-active"
            class="text-brandColor hover:text-blue-700 no-underline font-semibold"
          >
            {{ listDetailItem.firstName }} {{ listDetailItem.lastName }}
          </a>
        </td>
        <td>{{ listDetailItem.jobTitle }}</td>
        <td>{{ listDetailItem.location }}</td>
        <td>{{ listDetailItem.summary }}</td>
        <td>{{ listDetailItem.insight }}</td>
        <td>
          <a [href]="listDetailItem.url"><i class="pi pi-link"></i></a>
        </td>
        <td>{{ listDetailItem.lastHarvest | date }}</td>
        <td>{{ listDetailItem.errorHarvesting ? "Yes" : "No" }}</td>
        <td>
          <i [routerLink]="[listDetailItem.leadId]">
            <img src="assets\dashboard\lists\icons\ungroup.svg" alt="view icon" class="cursor-pointer" />
          </i>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
