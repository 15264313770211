<p-card
  header="Available lists"
  class="[&_.p-card]:rounded-xl [&_.p-card]:border [&_.p-card-content]:p-0 [&_.p-card]:border-solid [&_.p-card]:border-gray-200 [&_.p-card]:px-3 [&_.p-card]:py-2 [&_.p-card-title]:text-lg [&_.p-card-title]:font-bold [&_.p-card-title]:text-gray-700 [&_.p-card-title]:px-3.5 [&_.p-card-title]:py-3 [&_.p-card-body]:p-0"
>
  <p-table [value]="lists" class="[&_.p-datatable-tbody>tr:last-child>td]:border-b-0">
    <ng-template pTemplate="header">
      <tr class="[&>th]:bg-transparent [&>th]:py-2.5 [&>th]:px-3.5 [&>th]:text-sm [&>th]:text-gray-700">
        <th>Name</th>
        <th>Description</th>
        <th>Search Leads Total</th>
        <th>Search Leads Harvested</th>
        <th>Search Leads Errors</th>
        <th>Leads Total</th>
        <th>Leads Active</th>
        <th>Leads Expired</th>
        <th>Leads Harvested</th>
        <th>Leads Errors</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-listItem>
      <tr class="[&>td]:py-2.5 [&>td]:px-3.5 [&>td]:text-sm">
        <td>
          <a
            [routerLink]="[listItem.name, listItem.id]"
            routerLinkActive="router-link-active"
            class="text-brandColor hover:text-blue-700 no-underline font-semibold"
          >
            {{ listItem.name }}
          </a>
        </td>
        <td>{{ listItem.description }}</td>
        <td>{{ listItem.searchLeadsTotal }}</td>
        <td>{{ listItem.searchLeadsHarvested }}</td>
        <td>{{ listItem.searchLeadsErrors }}</td>
        <td>{{ listItem.leadsTotal }}</td>
        <td>{{ listItem.leadsActive }}</td>
        <td>{{ listItem.leadsExpired }}</td>
        <td>{{ listItem.leadsHarvested }}</td>
        <td>{{ listItem.leadsErrors }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
