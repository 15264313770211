import { IListDetail, IListDetailDto } from '../interfaces/list-detail.interface';
import { IList, IListDto } from '../interfaces/list.interface';

export class ListMapper {
  static mapListDtoToModel(dto: IListDto): IList {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      searchLeadsTotal: dto.searchLeadsTotal,
      searchLeadsHarvested: dto.searchLeadsHarvested,
      searchLeadsErrors: dto.searchLeadsErrors,
      leadsTotal: dto.leadsTotal,
      leadsActive: dto.leadsActive,
      leadsExpired: dto.leadsExpired,
      leadsHarvested: dto.leadsHarvested,
      leadsErrors: dto.leadsErrors,
    };
  }

  static mapListDetailDtoToModel(dto: IListDetailDto): IListDetail {
    return {
      leadId: dto.leadId,
      firstName: dto.firstName,
      lastName: dto.lastName,
      jobTitle: dto.jobTitle,
      location: dto.location,
      summary: dto.summary,
      insight: dto.insight,
      url: dto.url,
      picture: dto.picture,
      lastHarvest: dto.lastHarvest,
      errorHarvesting: dto.errorHarvesting,
    };
  }
}
