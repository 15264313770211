import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { TableModule } from 'primeng/table';
import { ListController } from '../../@core/api/list.api';
import { ListMapper } from '../../@core/api/list.mapper';
import { IListDetail } from '../../@core/interfaces/list-detail.interface';
@Component({
  selector: 'app-searches-list',
  standalone: true,
  templateUrl: './searches-list.component.html',
  styleUrl: './searches-list.component.scss',
  imports: [CardModule, TableModule, RouterLink, AvatarModule, ChipModule, CommonModule],
})
export class SearchesListComponent implements OnInit {
  listDetail!: IListDetail[];
  fullName: string;
  name: string = '';

  id: string = '';

  constructor(
    private listService: ListController,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.name = params['name'];
    });
    this.getListDetails();
  }

  private getListDetails(): void {
    this.listService.getListById(this.id).then((data) => {
      if (data) {
        this.listDetail = data.map((item) => ListMapper.mapListDetailDtoToModel(item));
      }
    });
  }
}
